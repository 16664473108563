var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn",
    {
      ref: "QQCheckBox",
      staticClass: "rounded-borders no-border q-ma-xs",
      class: _vm.getComponentClass,
      attrs: {
        size: _vm.size,
        outline: "",
        disabled: _vm.disabled,
        color: "color",
      },
      nativeOn: {
        click: function ($event) {
          return _vm.ModificaStato.apply(null, arguments)
        },
      },
    },
    [
      _c("q-avatar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.is_checked,
            expression: "is_checked",
          },
        ],
        staticClass: "posiziona_check",
        staticStyle: { border: "1px SOLID #000" },
        attrs: {
          icon: _vm.iconOn,
          size: "50px",
          name: "Icona_checked",
          color: "white",
          "text-color": "primary",
        },
      }),
      _c(
        "q-list",
        { staticClass: "full-width" },
        [
          _c(
            "q-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.icon != "",
                  expression: "icon != ''",
                },
              ],
              staticClass: "no-margin no-padding justify-center",
            },
            [
              _c("q-icon", {
                staticStyle: { "font-size": "3.0em" },
                attrs: { name: _vm.icon },
              }),
            ],
            1
          ),
          _c(
            "q-item",
            [
              _c(
                "q-item-section",
                { staticClass: "no-margin no-padding" },
                [
                  _c("q-item-label", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }